import { useEffect, useRef } from "react";
import Plivo from "plivo-browser-sdk";
function App() {
  const plivoRef = useRef(null);
  const voiceRef = useRef(null);
  const ref = useRef();
  const AUTHID = "MAOGE4ODRIZTGZYTYZMD";

  useEffect(() => {
    var options = {
      debug: "DEBUG",
      permOnClick: true,
      enableTracking: true,
      closeProtection: true,
      maxAverageBitrate: 48000,
      allowMultipleIncomingCalls: true,
    };
    sendToApp({ type: "started", data: "starting message from plivo" });
    const plivo = new Plivo(options);

    if (plivo) {
      plivoRef.current = plivo.client;
    }
  }, []);

  useEffect(() => {
    const handleMessage = (e) => {
      console.log("message", e.data);
      const data = JSON.parse(e.data);

      if (data.origin !== "ld-mobile-app") {
        return;
      }

      if (data.type === "login") {
        sendToApp({ type: "isLoggedIn", data: plivoRef.current.isLoggedIn });
        if (!plivoRef.current.isLoggedIn) {
          voiceRef.current = data.data.id;
          sendToApp({ type: "voiceId", data: voiceRef.current });
          plivoRef.current.login(voiceRef.current, "LeadsWatch@123");
        }
      }

      if (data.type === "accepted") {
        global.showForm = true;
        if (plivoRef.current.isLoggedIn) {
          plivoRef.current.answer(data.data.callUUID);
        }
      } else if (data.type === "rejected") {
        if (plivoRef.current.isLoggedIn) {
          plivoRef.current.reject(data.data.callUUID);
        }
      } else if (data.type === "hold") {
        plivoRef.current.hold();
      } else if (data.type === "unhold") {
      } else if (data.type === "hangup") {
        global.showForm = data.showForm;
        plivoRef.current.hangup();
      } else if (data.type === "mute") {
        plivoRef.current.mute();
        sendToApp({ type: "mute", data: data.data });
      } else if (data.type === "unmute") {
        plivoRef.current.unmute();
        sendToApp({ type: "unmute", data: data.data });
      } else if (data.type === "call") {
        if (plivoRef.current.isLoggedIn) {
          // sendToApp({ type: "calling", data: data.data });
          plivoRef.current.call(data.data);
        }
      }
    };

    document.addEventListener("message", function(e) {
      handleMessage(e);
    });
    window.addEventListener("message", function(e) {
      handleMessage(e);
    });
  }, []);

  const sendToApp = (data) => {
    if (!window.ReactNativeWebView) {
      return;
    }
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ ...data, origin: "ld-web-app" })
    );
  };

  const addMPC = async (data) => {
    try {
      const response = await fetch(
        `https://b7ff-150-107-212-177.ngrok-free.app/v1/calls/conference`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: "myconf",
          }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();
        // alert("resError" + JSON.stringify(resError));
        console.log("resError", resError);
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const result = await response.json();
        // alert("res" + JSON.stringify(result));
        console.log("result", result);

        return true;
      }
    } catch (err) {
      console.log("err", err);
      throw new Error(err.message);
    }
  };

  useEffect(() => {
    if (plivoRef.current) {
      function onLogin() {
        if (voiceRef.current) {
          sendToApp({
            type: "connected",
            data: voiceRef.current,
          });
        }
      }

      function onLogout() {
        sendToApp({
          type: "disconnected",
          data: voiceRef.current,
        });
      }

      function onLoginFailed() {
        sendToApp({
          type: "loginfailed",
          data: voiceRef.current,
        });
      }

      function onCallRemoteRinging(callInfo) {
        sendToApp({
          type: "ringing",
          data: { ...callInfo },
        });
      }

      function onIncomingCallCanceled(callInfo) {
        sendToApp({ type: "hangup", data: callInfo });
      }

      function onIncomingCallIgnored(callInfo) {
        sendToApp({
          type: "ignored",
          data: callInfo,
        });
      }

      function onCallFailed(cause, callInfo) {
        sendToApp({
          type: "rejected",
          data: { ...callInfo, cause },
        });
      }

      function onCallAnswered(callInfo) {
        sendToApp({
          type: "answered",
          data: callInfo,
        });
      }

      function onCallTerminated(info) {
        sendToApp({
          type: "terminated",
          data: { ...info, showForm: global.showForm },
        });
      }

      function onMediaConnected() {
        console.log("media connected");
      }

      function onCalling(callInfo) {
        sendToApp({
          type: "call",
          data: { ...callInfo },
        });
      }

      function onIncomingCall(callerName, extraHeaders, callInfo, caller_Name) {
        sendToApp({
          type: "ringing",
          data: { ...callInfo, callerName, caller_Name, extraHeaders },
        });
      }

      function onMediaPermission() {
        console.log("media permission");
      }

      function mediaMetrics() {
        console.log("media metrics");
      }

      function onConnectionChange(info) {
        console.log("connection change", info);
      }

      function onWebrtcNotSupported() {
        console.log("WebRTC not supported");
      }

      plivoRef.current.on("onWebrtcNotSupported", onWebrtcNotSupported);
      plivoRef.current.on("onLogin", onLogin);
      plivoRef.current.on("onLogout", onLogout);
      plivoRef.current.on("onLoginFailed", onLoginFailed);
      plivoRef.current.on("onCallRemoteRinging", onCallRemoteRinging);
      plivoRef.current.on("onIncomingCallCanceled", onIncomingCallCanceled);
      plivoRef.current.on("onIncomingCallIgnored", onIncomingCallIgnored);
      plivoRef.current.on("onCallFailed", onCallFailed);
      plivoRef.current.on("onCallAnswered", onCallAnswered);
      plivoRef.current.on("onCallTerminated", onCallTerminated);
      plivoRef.current.on("onMediaConnected", onMediaConnected);
      plivoRef.current.on("onCalling", onCalling);
      plivoRef.current.on("onIncomingCall", onIncomingCall);
      plivoRef.current.on("onMediaPermission", onMediaPermission);
      plivoRef.current.on("mediaMetrics", mediaMetrics);
      plivoRef.current.on("onConnectionChange", onConnectionChange);
    }
  }, []);

  return null;
  // (
  //   <div
  //     style={{
  //       height: "100vh",
  //       width: "100%",
  //       justifyContent: "center",
  //       alignItems: "center",
  //       display: "flex",
  //       padding: 30,
  //     }}
  //     ref={ref}
  //   >
  //     waiting for...
  //   </div>
  // );
}

export default App;
